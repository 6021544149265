<template>
    <div class="main-container">

        <Header/>

        <Breadcrumb title="Services" />

        <MainConstruction />

        <MainMEPFService />

        <!-- <div class="page-wrapper section-space--bottom--50">
            Service section start
            <div class="service-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-6 col-12">
                            <div class="section-title-area text-center">
                                <h2 class="section-title section-space--bottom--50">
                                    {{ data.serviceOne.serviceslider }}
                                    <br><i class="fas fa-chevron-down"></i>
                                </h2>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service-item-wrapper">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-12 section-space--bottom--30" v-for="service in data.serviceHome.serviceList" :key="service.id">
                                        <ServiceItem :service="service" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            Service section end
        </div> -->

        <BrandCarousel />

        <Footer />

        <OffCanvasMobileMenu />

        <!-- back to top start -->
        <back-to-top class="scroll-top" bottom="60px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top>
        <!-- back to top end -->
    </div>
</template>

<script>
    import data from '../data/service.json'
    import Header from '@/components/Header'
    import Breadcrumb from '../components/Breadcrumb'
    import MainConstruction from './MainConstruction'
    import MainMEPFService from './MainMEPFService'
    import ServiceItem from '../components/ServiceItem'
    import BrandCarousel from '../components/BrandCarousel'
    import Footer from '../components/Footer'
    import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu'
    export default {
        components: {
            Header,
            Breadcrumb,
            MainConstruction,
            MainMEPFService,
            ServiceItem,
            BrandCarousel,
            Footer,
            OffCanvasMobileMenu
        },
        data() {
            return {
                data,
            }
        },
        metaInfo: {
            title: 'STS CAMBODIA - Service',
            htmlAttrs: {
                lang: 'en',
                amp: true
            }
        }
    }
</script>

