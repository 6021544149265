<template>
    <div class="service-grid-item">
        <div class="service-grid-item__image">
            <div class="service-grid-item__image-wrapper">
                <router-link @click.native="scrollToTop" :to="`/service-details/${ mainservice.id }`">
                    <img :src="mainservice.image" class="img-fluid" alt="service thumb">
                </router-link>
            </div>
            <div class="icon">
                <i :class="mainservice.icon"></i>
            </div>
        </div>
        <div class="service-grid-item__content">
            <h3 class="title">
                <router-link @click.native="scrollToTop" :to="`/service-details/${ mainservice.id }`">
                    {{ mainservice.title }}
                </router-link>
            </h3>
            <p class="subtitle">{{ mainservice.desc }}</p>
            <router-link @click.native="scrollToTop" class="see-more-link" :to="`/service-details/${ mainservice.id }`">SEE MORE</router-link>
        </div>
    </div>
</template>

<script>
import data from '../data/service.json'

export default {
    props: ['mainservice'],
    data() {
        return {
            data
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0)
        }
    }
};
</script>