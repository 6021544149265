<template>
    <div class="service-grid-item">
        <div class="service-grid-item__image">
            <div class="service-grid-item__image-wrapper">
                <router-link @click.native="scrollToTop" to="/service-details/2">
                    <img :src="mainmepfservice.image" class="img-fluid" alt="service thumb">
                </router-link>
            </div>
            <div class="icon">
                <i :class="mainmepfservice.icon"></i>
            </div>
        </div>
        <div class="service-grid-item__content">
            <h3 class="title">
                <router-link @click.native="scrollToTop" to="/service-details/2">
                    {{ mainmepfservice.title }}
                </router-link>
            </h3>
            <p class="subtitle">{{ mainmepfservice.desc }}</p>
            <router-link @click.native="scrollToTop" class="see-more-link" to="/service-details/2">SEE MORE</router-link>
        </div>
    </div>
</template>

<script>
import data from '../data/service.json'

export default {
    props: ['mainmepfservice'],
    data() {
        return {
            data
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0)
        }
    }
};
</script>